import { Link } from 'gatsby';

import { FaHeart } from '@react-icons/all-files/fa/FaHeart';

import skyline from '../../images/Skyline.svg';
import SectionTitle from '../atoms/section-title';

const Footer = () => {
  return (
    <>
      <div
        style={{ backgroundImage: `url(${skyline})` }}
        className="h-[70px] bg-cover bg-repeat-x md:h-[150px] xl:bg-contain"
      />
      <SectionTitle index={0}>
        <Link to="/impressum" className="hover:underline active:underline">
          Impressum
        </Link>
        {' | '}
        <Link to="/datenschutz" className="hover:underline active:underline">
          Datenschutz
        </Link>
      </SectionTitle>
      <div className="bg-af-blue-dark p-1 text-center text-white">
        <span className="border-t border-white p-1">
          made with <FaHeart className="inline" /> by{' '}
          <a
            href="https://simpel-web.de"
            target="_blank"
            rel="noopener noreferrer"
            className="break-keep underline"
          >
            Simpel&nbsp;Web
          </a>
          {' / '}
          <a
            href="http://www.san2design.de/"
            target="_blank"
            rel="noopener noreferrer"
            className="break-keep underline"
          >
            san2design
          </a>
        </span>
      </div>
    </>
  );
};

export default Footer;
